import { DEV_CIAM_ID, PAGE_BEHAVIOR_OPTIONS, PAGE_QUERY_PARAMETERS } from './constants';

export const updateCiamId = (setCiamIdOption: PAGE_BEHAVIOR_OPTIONS): void => {
    const currentQueryParams = new URLSearchParams(window.location.search);
    if (setCiamIdOption === PAGE_BEHAVIOR_OPTIONS.set) {
        if (!currentQueryParams.has(PAGE_QUERY_PARAMETERS.ciamId)) {
            currentQueryParams.append(PAGE_QUERY_PARAMETERS.ciamId, DEV_CIAM_ID);
            window.location.search = currentQueryParams.toString();
        }
    } else if (setCiamIdOption === PAGE_BEHAVIOR_OPTIONS.clear) {
        if (currentQueryParams.has(PAGE_QUERY_PARAMETERS.ciamId)) {
            currentQueryParams.delete(PAGE_QUERY_PARAMETERS.ciamId);
            window.location.search = currentQueryParams.toString();
        }
    } else {}
};
