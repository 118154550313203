import * as React from 'react';
import * as ReactDOM from 'react-dom';
import onDomContentLoaded from '../utilities/onDomContentLoaded';
import DevTools from './DevTools';
import '../../scss/dev-tools/main.scss';

onDomContentLoaded(() => {
    if (window.location.search.includes('devTools=true')) {
        const devToolsContainerEl = document.getElementById('dev-tools-container');

        if (devToolsContainerEl) {
            ReactDOM.render(<DevTools />, devToolsContainerEl);
        }
    }
});
