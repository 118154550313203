export enum PAGE_BEHAVIOR_OPTIONS {
    set = 'SET',
    clear = 'CLEAR',
    ignore = 'IGNORE',
    expired = 'EXPIRED',
}

export enum PAGE_QUERY_PARAMETERS {
    ciamId = 'useDevCiamId',
}

// This works today, 11/9/2023, but will likely periodically need to be updated
export const DEV_CIAM_ID = 'a6184c03-2ee6-46b4-be1b-9da9b311846a';

export const NEAREST_STORE_ID = '7173';
export const NEAREST_STORE_LOCAL_STORAGE_KEY = 'kmx-nearest-store';
export const buildNearestStoreObject = () => {
    const ONE_DAY_EXPIRATION = new Date().getTime() + 86400000;

    return JSON.stringify({
        store: {
            Id: NEAREST_STORE_ID,
            Name: 'Kansas City',
            DisplayName: 'Kansas City',
            Subtitle: '',
            LocationDisplay: 'Merriam, KS',
            TelephoneNumber: '(913) 982-8863',
            TollFreePhoneNumber: '(855) 262-6431',
            TelephoneNumbers: ['Sales (913) 982-8863', 'General (913) 384-8561'],
            SalesTelephoneNumbers: ['Local (913) 384-8561', 'Toll Free (866) 629-0145', 'Fax (913) 384-8553'],
            GeneralTelephoneNumbers: ['Local (913) 982-8863', 'Toll Free (855) 262-6431', 'Fax (913) 384-8556'],
            ShowroomHours: ['Sun 12-7', 'Mon-Fri 10-9', 'Sat 9-9'],
            TelephoneHours: ['Sun 11-7', 'Mon-Sat 9-9'],
            ServiceHours: ['Mon-Fri 7:30-6'],
            Latitude: 39.005732,
            Longitude: -94.693528,
            AddressLine1: '6801 East Frontage Road',
            AddressLine2: '',
            City: 'Merriam',
            State: 'KS',
            ZipCode: '66204',
            HolidayHours: [],
            Status: 'active',
            Categories: ['Service', 'Appraisals', 'Used Car Sales'],
            IsTemporarilyClosed: false,
            Links: [
                {
                    Rel: 'self',
                    Method: 'GET',
                    Href: `https://apiqa.carmax.com/v1/api/stores/${NEAREST_STORE_ID}`,
                    Title: 'Store Detail',
                },
                {
                    Rel: 'save',
                    Method: 'POST',
                    Href: `https://apiqa.carmax.com/v1/api/stores/${NEAREST_STORE_ID}`,
                    Title: 'Save As Nearest',
                },
                {
                    Rel: 'search',
                    Method: 'GET',
                    Href: `https://apiqa.carmax.com/v1/api/vehicles?locationid=${NEAREST_STORE_ID}&distance=store`,
                    Title: 'See All Cars at This Location',
                },
                {
                    Rel: 'lead',
                    Method: 'post',
                    Href: 'https://apiqa.carmax.com/v1/api/leads/moreinfo',
                    Title: 'Contact a Sales Consultant',
                },
                {
                    Rel: 'BrowseAppointment',
                    Method: 'POST',
                    Href: `https://apiqa.carmax.com/v1/api/appointments/${NEAREST_STORE_ID}/store?appointmenttype=browse`,
                    Title: 'Make a Browse Appointment',
                },
                {
                    Rel: 'AppraisalAppointment',
                    Method: 'POST',
                    Href: `https://apiqa.carmax.com/v1/api/appointments/${NEAREST_STORE_ID}/store?appointmenttype=appraisal`,
                    Title: 'Make an Appraisal Appointment',
                },
            ],
        },
        expires: ONE_DAY_EXPIRATION,
    });
};

export const RECENTLY_VIEWED_VEHICLES_LOCAL_STORAGE_KEY = 'viewedVehicles';
export const buildSearchApi = (count: number) =>
    `https://wwwqa.carmax.com/home/api/search?make=acura&make=gmc&make=jeep&make=toyota&isSaleable=true&take=${count}`;

export const INSTANT_OFFER_ELIGIBLE_KEY = 'suycIcoOffer';
export const buildInstantOfferEligibleObject = (expired: boolean) => {
    const DATE = new Date();
    if (expired) {
        DATE.setDate(DATE.getDate() - 20);
    } else {
        DATE.setDate(DATE.getDate() + 7);
    }

    return JSON.stringify({
        offerAmount: 12000,
        offerCode: 'foobar',
        expirationDisplayDate: DATE,
        storeId: NEAREST_STORE_ID,
        vin: '123456789abcdefg',
        year: 2015,
        make: 'Honda',
        model: 'Civic',
        trim: 'DX',
    });
};

export const INSTANT_OFFER_INELIGIBLE_KEY = 'suycIcoOfferIneligible';
export const buildInstantOfferIneligibleObject = (expired: boolean) => {
    const DATE = new Date();
    if (expired) {
        DATE.setDate(DATE.getDate() - 20);
    } else {
        DATE.setDate(DATE.getDate() + 7);
    }
    return JSON.stringify({ expirationDisplayDate: DATE });
};
