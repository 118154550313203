import axios from 'axios';
import { buildSearchApi, RECENTLY_VIEWED_VEHICLES_LOCAL_STORAGE_KEY, PAGE_BEHAVIOR_OPTIONS } from './constants';

export const updateRecentlyViewedVehicles = (setRecentlyViewedVehiclesOption: PAGE_BEHAVIOR_OPTIONS, count = 5) => {
    if (setRecentlyViewedVehiclesOption === PAGE_BEHAVIOR_OPTIONS.set) {
        const TIMESTAMP = Date.now();

        try {
            return axios
                .get(buildSearchApi(count))
                .then((response) => {
                    if (response && response.data && response.data.items) {
                        const recentlyViewedVehiclesLocalStorage = response.data.items.map((vehicle: any) => {
                            return { stockNumber: vehicle.stockNumber, timestamp: TIMESTAMP, viewCount: 1 };
                        });

                        window.localStorage.setItem(
                            RECENTLY_VIEWED_VEHICLES_LOCAL_STORAGE_KEY,
                            JSON.stringify(recentlyViewedVehiclesLocalStorage)
                        );
                    } else {
                        window.localStorage.removeItem(RECENTLY_VIEWED_VEHICLES_LOCAL_STORAGE_KEY);
                    }
                })
                .catch(() => {
                    window.localStorage.removeItem(RECENTLY_VIEWED_VEHICLES_LOCAL_STORAGE_KEY);
                });
        } catch {
            window.localStorage.removeItem(RECENTLY_VIEWED_VEHICLES_LOCAL_STORAGE_KEY);
        }
    } else if (setRecentlyViewedVehiclesOption === PAGE_BEHAVIOR_OPTIONS.clear) {
        window.localStorage.removeItem(RECENTLY_VIEWED_VEHICLES_LOCAL_STORAGE_KEY);
    }
};
