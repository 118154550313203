import { NEAREST_STORE_LOCAL_STORAGE_KEY, buildNearestStoreObject, PAGE_BEHAVIOR_OPTIONS } from './constants';

export const updateNearestStore = (setNearestStoreOption: PAGE_BEHAVIOR_OPTIONS) => {
    if (setNearestStoreOption === PAGE_BEHAVIOR_OPTIONS.set) {
        window.localStorage.setItem(NEAREST_STORE_LOCAL_STORAGE_KEY, buildNearestStoreObject());
    } else if (setNearestStoreOption === PAGE_BEHAVIOR_OPTIONS.clear) {
        window.localStorage.removeItem(NEAREST_STORE_LOCAL_STORAGE_KEY);
    } else {
    }
};
