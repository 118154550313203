import * as React from 'react';
import classNames from 'classnames';
import * as constants from './constants';
import { updateNearestStore } from './NearestStore';
import { updateRecentlyViewedVehicles } from './RecentlyViewedVehicles';
import { updateRecentSearches } from './RecentSearches';
import { updateEligibleInstantOffer, updateIneligibleInstantOffer } from './InstantOffers';
import { updateCiamId } from './CiamId';

function DevTools() {
    const [open, toggleOpen] = React.useState(false);

    const [setNearestStoreOption, updateNearestStoreOption] = React.useState(constants.PAGE_BEHAVIOR_OPTIONS.ignore);

    const [setRecentlyViewedVehiclesOption, updateSetRecentlyViewedVehiclesOption] = React.useState(
        constants.PAGE_BEHAVIOR_OPTIONS.ignore
    );

    const [setRecentSearchesOption, updateSetRecentSearchesOption] = React.useState(
        constants.PAGE_BEHAVIOR_OPTIONS.ignore
    );

    const [setEligibleInstantOfferOption, updateSetEligibleInstantOfferOption] = React.useState(
        constants.PAGE_BEHAVIOR_OPTIONS.ignore
    );

    const [setIneligibleInstantOfferOption, updateSetIneligibleInstantOfferOption] = React.useState(
        constants.PAGE_BEHAVIOR_OPTIONS.ignore
    );

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        Promise.all([
            updateNearestStore(setNearestStoreOption),
            updateRecentlyViewedVehicles(setRecentlyViewedVehiclesOption),
            updateRecentSearches(setRecentSearchesOption),
            updateEligibleInstantOffer(setEligibleInstantOfferOption),
            updateIneligibleInstantOffer(setIneligibleInstantOfferOption),
        ]).then(() => window.location.reload());
    };

    const radioInputGroups = [
        {
            sectionHeading: 'Nearest store:',
            radioInputs: [
                {
                    id: 'setNearestStore',
                    checked: setNearestStoreOption === constants.PAGE_BEHAVIOR_OPTIONS.set,
                    handleChange: () => updateNearestStoreOption(constants.PAGE_BEHAVIOR_OPTIONS.set),
                    label: 'Set nearest store',
                },
                {
                    id: 'clearNearestStore',
                    checked: setNearestStoreOption === constants.PAGE_BEHAVIOR_OPTIONS.clear,
                    handleChange: () => updateNearestStoreOption(constants.PAGE_BEHAVIOR_OPTIONS.clear),
                    label: 'Clear nearest store',
                },
                {
                    id: 'nearestStoreNoAction',
                    checked: setNearestStoreOption === constants.PAGE_BEHAVIOR_OPTIONS.ignore,
                    handleChange: () => updateNearestStoreOption(constants.PAGE_BEHAVIOR_OPTIONS.ignore),
                    label: 'No action',
                },
            ],
        },
        {
            sectionHeading: 'Recently viewed vehicles:',
            radioInputs: [
                {
                    id: 'setRecentlyViewedVehicles',
                    checked: setRecentlyViewedVehiclesOption === constants.PAGE_BEHAVIOR_OPTIONS.set,
                    handleChange: () => updateSetRecentlyViewedVehiclesOption(constants.PAGE_BEHAVIOR_OPTIONS.set),
                    label: 'Set 5 recently viewed vehicles',
                },
                {
                    id: 'clearRecentlyViewedVehicles',
                    checked: setRecentlyViewedVehiclesOption === constants.PAGE_BEHAVIOR_OPTIONS.clear,
                    handleChange: () => updateSetRecentlyViewedVehiclesOption(constants.PAGE_BEHAVIOR_OPTIONS.clear),
                    label: 'Clear recently viewed vehicles',
                },
                {
                    id: 'recentlyViewedVehiclesNoAction',
                    checked: setRecentlyViewedVehiclesOption === constants.PAGE_BEHAVIOR_OPTIONS.ignore,
                    handleChange: () => updateSetRecentlyViewedVehiclesOption(constants.PAGE_BEHAVIOR_OPTIONS.ignore),
                    label: 'No action',
                },
            ],
        },
        {
            sectionHeading: 'Recent searches:',
            radioInputs: [
                {
                    id: 'setRecentSearches',
                    checked: setRecentSearchesOption === constants.PAGE_BEHAVIOR_OPTIONS.set,
                    handleChange: () => updateSetRecentSearchesOption(constants.PAGE_BEHAVIOR_OPTIONS.set),
                    label: 'Set 5 recent searches',
                },
                {
                    id: 'clearRecentSearches',
                    checked: setRecentSearchesOption === constants.PAGE_BEHAVIOR_OPTIONS.clear,
                    handleChange: () => updateSetRecentSearchesOption(constants.PAGE_BEHAVIOR_OPTIONS.clear),
                    label: 'Clear recent searches',
                },
                {
                    id: 'recentSearchesNoAction',
                    checked: setRecentSearchesOption === constants.PAGE_BEHAVIOR_OPTIONS.ignore,
                    handleChange: () => updateSetRecentSearchesOption(constants.PAGE_BEHAVIOR_OPTIONS.ignore),
                    label: 'No action',
                },
            ],
        },
        {
            sectionHeading: 'Eligible instant offer:',
            radioInputs: [
                {
                    id: 'setEligibleInstantOffer',
                    checked: setEligibleInstantOfferOption === constants.PAGE_BEHAVIOR_OPTIONS.set,
                    handleChange: () => updateSetEligibleInstantOfferOption(constants.PAGE_BEHAVIOR_OPTIONS.set),
                    label: 'Set eligible instant offer',
                },
                {
                    id: 'expiredEligibleInstantOffer',
                    checked: setEligibleInstantOfferOption === constants.PAGE_BEHAVIOR_OPTIONS.expired,
                    handleChange: () => updateSetEligibleInstantOfferOption(constants.PAGE_BEHAVIOR_OPTIONS.expired),
                    label: 'Set expired eligible instant offer',
                },
                {
                    id: 'clearRecentSearches',
                    checked: setEligibleInstantOfferOption === constants.PAGE_BEHAVIOR_OPTIONS.clear,
                    handleChange: () => updateSetEligibleInstantOfferOption(constants.PAGE_BEHAVIOR_OPTIONS.clear),
                    label: 'Clear eligible instant offer',
                },
                {
                    id: 'eligibleInstantOfferNoAction',
                    checked: setEligibleInstantOfferOption === constants.PAGE_BEHAVIOR_OPTIONS.ignore,
                    handleChange: () => updateSetEligibleInstantOfferOption(constants.PAGE_BEHAVIOR_OPTIONS.ignore),
                    label: 'No action',
                },
            ],
        },
        {
            sectionHeading: 'Ineligible instant offer:',
            radioInputs: [
                {
                    id: 'setIneligibleInstantOffer',
                    checked: setIneligibleInstantOfferOption === constants.PAGE_BEHAVIOR_OPTIONS.set,
                    handleChange: () => updateSetIneligibleInstantOfferOption(constants.PAGE_BEHAVIOR_OPTIONS.set),
                    label: 'Set ineligible instant offer',
                },
                {
                    id: 'expiredIneligibleInstantOffer',
                    checked: setIneligibleInstantOfferOption === constants.PAGE_BEHAVIOR_OPTIONS.expired,
                    handleChange: () => updateSetIneligibleInstantOfferOption(constants.PAGE_BEHAVIOR_OPTIONS.expired),
                    label: 'Set expired ineligible instant offer',
                },
                {
                    id: 'clearIneligibleInstantOffer',
                    checked: setIneligibleInstantOfferOption === constants.PAGE_BEHAVIOR_OPTIONS.clear,
                    handleChange: () => updateSetIneligibleInstantOfferOption(constants.PAGE_BEHAVIOR_OPTIONS.clear),
                    label: 'Clear ineligible instant offer',
                },
                {
                    id: 'ineligibleInstantOfferNoAction',
                    checked: setIneligibleInstantOfferOption === constants.PAGE_BEHAVIOR_OPTIONS.ignore,
                    handleChange: () => updateSetIneligibleInstantOfferOption(constants.PAGE_BEHAVIOR_OPTIONS.ignore),
                    label: 'No action',
                },
            ],
        },
    ];

    const buttonGroups = [
        {
            sectionHeading: 'Ciam ID:',
            buttons: [
                {
                    id: 'setCiamId',
                    handleChange: () => updateCiamId(constants.PAGE_BEHAVIOR_OPTIONS.set),
                    label: 'Set ciam ID',
                },
                {
                    id: 'clearCiamId',
                    handleChange: () => updateCiamId(constants.PAGE_BEHAVIOR_OPTIONS.clear),
                    label: 'Clear ciam ID',
                },
            ],
        },
    ];

    return (
        <>
            <div className="dev-tools-controls-toggle">
                <button
                    onClick={(e: any) => {
                        e.preventDefault();
                        toggleOpen(!open);
                    }}
                    aria-label="Open home microsite dev tools"
                    className="dev-tools-controls-toggle-button"
                >
                    <svg
                        className="dev-tools-controls-toggle-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path d="M444.788 291.1l42.616 24.599c4.867 2.809 7.126 8.618 5.459 13.985-11.07 35.642-29.97 67.842-54.689 94.586a12.016 12.016 0 0 1-14.832 2.254l-42.584-24.595a191.577 191.577 0 0 1-60.759 35.13v49.182a12.01 12.01 0 0 1-9.377 11.718c-34.956 7.85-72.499 8.256-109.219.007-5.49-1.233-9.403-6.096-9.403-11.723v-49.184a191.555 191.555 0 0 1-60.759-35.13l-42.584 24.595a12.016 12.016 0 0 1-14.832-2.254c-24.718-26.744-43.619-58.944-54.689-94.586-1.667-5.366.592-11.175 5.459-13.985L67.212 291.1a193.48 193.48 0 0 1 0-70.199l-42.616-24.599c-4.867-2.809-7.126-8.618-5.459-13.985 11.07-35.642 29.97-67.842 54.689-94.586a12.016 12.016 0 0 1 14.832-2.254l42.584 24.595a191.577 191.577 0 0 1 60.759-35.13V25.759a12.01 12.01 0 0 1 9.377-11.718c34.956-7.85 72.499-8.256 109.219-.007 5.49 1.233 9.403 6.096 9.403 11.723v49.184a191.555 191.555 0 0 1 60.759 35.13l42.584-24.595a12.016 12.016 0 0 1 14.832 2.254c24.718 26.744 43.619 58.944 54.689 94.586 1.667 5.366-.592 11.175-5.459 13.985L444.788 220.9a193.485 193.485 0 0 1 0 70.2zM336 256c0-44.112-35.888-80-80-80s-80 35.888-80 80 35.888 80 80 80 80-35.888 80-80z" />
                    </svg>
                    <span className="visually-hidden">Open home microsite dev tools</span>
                </button>
            </div>
            <div
                className={classNames('dev-tools-form', {
                    'dev-tools-form-hidden': !open,
                })}
            >
                <h3 className="kmx-typography--display-2">Home microsite dev tools</h3>
                <form onSubmit={handleFormSubmit}>
                    {radioInputGroups.map((radioInputGroup, index) => (
                        <div key={index}>
                            <p className="kmx-typography--display-1">{radioInputGroup.sectionHeading}</p>
                            {radioInputGroup.radioInputs.map((radioInput, index) => (
                                <div key={index}>
                                    <input
                                        id={radioInput.id}
                                        type="radio"
                                        checked={radioInput.checked}
                                        onChange={radioInput.handleChange}
                                    />
                                    <label className="kmx-typography--body-1" htmlFor={radioInput.id}>
                                        {radioInput.label}
                                    </label>
                                </div>
                            ))}
                            <br />
                        </div>
                    ))}
                    <div></div>
                    <button className="dev-tools-form-submit kmx-typography--body-1" type="submit">
                        Update data and reload page
                    </button>
                </form>

                <div className="dev-tools-form-button-group-container">
                    {buttonGroups.map((buttonGroup, index) => (
                        <div key={index}>
                            <p className="kmx-typography--display-1">{buttonGroup.sectionHeading}</p>
                            {buttonGroup.buttons.map((button, index) => (
                                <div key={index}>
                                    <button
                                        className="dev-tools-form-button kmx-typography--body-1"
                                        id={button.id}
                                        onClick={button.handleChange}
                                    >
                                        {button.label}
                                    </button>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default DevTools;
