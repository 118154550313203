import {
    INSTANT_OFFER_ELIGIBLE_KEY,
    INSTANT_OFFER_INELIGIBLE_KEY,
    buildInstantOfferEligibleObject,
    buildInstantOfferIneligibleObject,
    PAGE_BEHAVIOR_OPTIONS
} from './constants';

export const updateEligibleInstantOffer = (setEligibleInstantOfferOption: PAGE_BEHAVIOR_OPTIONS) => {
    if (
        setEligibleInstantOfferOption === PAGE_BEHAVIOR_OPTIONS.set ||
        setEligibleInstantOfferOption === PAGE_BEHAVIOR_OPTIONS.expired
    ) {
        window.localStorage.setItem(
            INSTANT_OFFER_ELIGIBLE_KEY,
            buildInstantOfferEligibleObject(setEligibleInstantOfferOption === PAGE_BEHAVIOR_OPTIONS.expired)
        );
    } else if (setEligibleInstantOfferOption === PAGE_BEHAVIOR_OPTIONS.clear) {
        window.localStorage.removeItem(INSTANT_OFFER_ELIGIBLE_KEY);
    } else {
    }
};

export const updateIneligibleInstantOffer = (setIneligibleInstantOfferOption: PAGE_BEHAVIOR_OPTIONS) => {
    if (
        setIneligibleInstantOfferOption === PAGE_BEHAVIOR_OPTIONS.set ||
        setIneligibleInstantOfferOption === PAGE_BEHAVIOR_OPTIONS.expired
    ) {
        window.localStorage.setItem(
            INSTANT_OFFER_INELIGIBLE_KEY,
            buildInstantOfferIneligibleObject(setIneligibleInstantOfferOption === PAGE_BEHAVIOR_OPTIONS.expired)
        );
    } else if (setIneligibleInstantOfferOption === PAGE_BEHAVIOR_OPTIONS.clear) {
        window.localStorage.removeItem(INSTANT_OFFER_INELIGIBLE_KEY);
    } else {
    }
};
