import { PAGE_BEHAVIOR_OPTIONS } from './constants';

export const updateRecentSearches = (setRecentSearchesOption: PAGE_BEHAVIOR_OPTIONS) => {
    const visitorCookieName = 'KmxVisitor_0';
    const visitorCookieKey = 'VisitorID';
    const visitorId = '958130d5-d879-450a-bbb8-3f1f487d614c';

    if (setRecentSearchesOption === PAGE_BEHAVIOR_OPTIONS.set) {
        document.cookie = `${visitorCookieName}=${visitorCookieKey}=${visitorId};domain=.carmax.com;`;
    } else if (setRecentSearchesOption === PAGE_BEHAVIOR_OPTIONS.clear) {
        document.cookie = `${visitorCookieName}=${visitorCookieKey}=;domain=.carmax.com;`;
    } else {
    }
};
